export const META_INFO = {
    // General Urls
    "/": {
        title: "Best Software Training Institute in Hyderabad - SSSIT Computer Education",
        description: "Training offered by Industry experts in Java, DotNet, Python, .Net core, Dotnet core, .Net, AWS & Devops, Azure DevOps in Kukatpally, KPHB, Hyderabad.",
        keywords: 'Dot net training, Job training institute in hyderabad, Software training in KPHB, Job training in JNTU Kukatpally, Software training in JNTU Kukatpally, Angular Training, Best training in KPHB, Best training in Kukatpally, Testing Best training institute in Kukatpally KPHB, Best Software Training Institute for Java, C#.NET, ASP.NET, Testing Tools, Dot Net jobs in KPHB Kukatpally, Freshers training in KPHB Kukatpally, Freshers jobs in KPHB Kukatpally,Best IT Training in KPHB, IT Internships in KPHB, IT Workshops in KPHB, Website Development Company in KPHB, Best Digital Marketing Services in KPHB, .Net Training in KPHB, Best .Net Training in Kukatpally, Angular Training in KPHB, UI & UX Training in KPHB, Best IT Internship in KPHB, Workshops on Latest Technologies in KPHB, Best WebDesigning Training in KPHB'
    },
    "/aboutus": {
        title: "Best Software Training Institute in Hyderabad - SSSIT Computer Education",
        description: "Training offered by Industry experts in Java, DotNet, Python, .Net core, Dotnet core, .Net, AWS & Devops, Azure DevOps in Kukatpally, KPHB, Hyderabad.",
        keywords: 'Dot net training, Job training institute in hyderabad, Software training in KPHB, Job training in JNTU Kukatpally, Software training in JNTU Kukatpally, Angular Training, Best training in KPHB, Best training in Kukatpally, Testing Best training institute in Kukatpally KPHB, Best Software Training Institute for Java, C#.NET, ASP.NET, Testing Tools, Dot Net jobs in KPHB Kukatpally, Freshers training in KPHB Kukatpally, Freshers jobs in KPHB Kukatpally'
    },
    "/contactus": {
        title: "Best Software Training Institute in Hyderabad - SSSIT Computer Education",
        description: "Training offered by Industry experts in Java, DotNet, Python, .Net core, Dotnet core, .Net, AWS & Devops, Azure DevOps in Kukatpally, KPHB, Hyderabad.",
        keywords: 'Dot net training, Job training institute in hyderabad, Software training in KPHB, Job training in JNTU Kukatpally, Software training in JNTU Kukatpally, Angular Training, Best training in KPHB, Best training in Kukatpally, Testing Best training institute in Kukatpally KPHB, Best Software Training Institute for Java, C#.NET, ASP.NET, Testing Tools, Dot Net jobs in KPHB Kukatpally, Freshers training in KPHB Kukatpally, Freshers jobs in KPHB Kukatpally'
    },
    "/workshops": {
        title: "Best Software Training Institute in Hyderabad - SSSIT Computer Education",
        description: "Training offered by Industry experts in Java, DotNet, Python, .Net core, Dotnet core, .Net, AWS & Devops, Azure DevOps in Kukatpally, KPHB, Hyderabad.",
        keywords: 'Dot net training, Job training institute in hyderabad, Software training in KPHB, Job training in JNTU Kukatpally, Software training in JNTU Kukatpally, Angular Training, Best training in KPHB, Best training in Kukatpally, Testing Best training institute in Kukatpally KPHB, Best Software Training Institute for Java, C#.NET, ASP.NET, Testing Tools, Dot Net jobs in KPHB Kukatpally, Freshers training in KPHB Kukatpally, Freshers jobs in KPHB Kukatpally'
    },
    "/internship": {
        title: "Best Software Training Institute in Hyderabad - SSSIT Computer Education",
        description: "Training offered by Industry experts in Java, DotNet, Python, .Net core, Dotnet core, .Net, AWS & Devops, Azure DevOps in Kukatpally, KPHB, Hyderabad.",
        keywords: 'Dot net training, Job training institute in hyderabad, Software training in KPHB, Job training in JNTU Kukatpally, Software training in JNTU Kukatpally, Angular Training, Best training in KPHB, Best training in Kukatpally, Testing Best training institute in Kukatpally KPHB, Best Software Training Institute for Java, C#.NET, ASP.NET, Testing Tools, Dot Net jobs in KPHB Kukatpally, Freshers training in KPHB Kukatpally, Freshers jobs in KPHB Kukatpally'
    },

    //Dotnet Full Stack
    "/dotnet-fullstacl-training": {
        title: "Best DotNet Training Institute in Hyderabad - SSSIT Computer Education",
        description: "Training offered by Industry experts in Java, DotNet, Python, .Net core, Dotnet core, .Net, AWS & Devops, Azure DevOps in Kukatpally, KPHB, Hyderabad.",
        keywords: 'best Dot net training in KPHB Kukatpally, Best dot net training institute in KPHB Kukatpally, Best dot net training institute in JNTU Kukatpally, Best dot net training institute in JNTU Hyderabad, Dot net training with job in Hyderabad, Dot net training with project, Dot net training with placement in Hyderabad'
    },
    "/dotnet/content": {
        title: "Best DotNet Training Institute in Hyderabad - SSSIT Computer Education",
        description: "Training offered by Industry experts in Java, DotNet, Python, .Net core, Dotnet core, .Net, AWS & Devops, Azure DevOps in Kukatpally, KPHB, Hyderabad.",
        keywords: 'Dot net training in KPHB Kukatpally, Best dot net training institute in KPHB Kukatpally, Best dot net training institute in JNTU Kukatpally, Best dot net training institute in JNTU Hyderabad, Dot net training with job in Hyderabad, Dot net training with project, Dot net training with placement in Hyderabad'
    },
    "/dotnet/about-trainer": {
        title: "Best DotNet Training Institute in Hyderabad - SSSIT Computer Education",
        description: "Training offered by Industry experts in Java, DotNet, Python, .Net core, Dotnet core, .Net, AWS & Devops, Azure DevOps in Kukatpally, KPHB, Hyderabad.",
        keywords: 'Dot net training in KPHB Kukatpally, Best dot net training institute in KPHB Kukatpally, Best dot net training institute in JNTU Kukatpally, Best dot net training institute in JNTU Hyderabad, Dot net training with job in Hyderabad, Dot net training with project, Dot net training with placement in Hyderabad'
    },
    "/dotnet/our-success-stories": {
        title: "Best DotNet Training Institute in Hyderabad - SSSIT Computer Education",
        description: "Training offered by Industry experts in Java, DotNet, Python, .Net core, Dotnet core, .Net, AWS & Devops, Azure DevOps in Kukatpally, KPHB, Hyderabad.",
        keywords: 'Dot net training in KPHB Kukatpally, Best dot net training institute in KPHB Kukatpally, Best dot net training institute in JNTU Kukatpally, Best dot net training institute in JNTU Hyderabad, Dot net training with job in Hyderabad, Dot net training with project, Dot net training with placement in Hyderabad'
    },
    "/dotnet/our-training-methodology": {
        title: "Best DotNet Training Institute in Hyderabad - SSSIT Computer Education",
        description: "Training offered by Industry experts in Java, DotNet, Python, .Net core, Dotnet core, .Net, AWS & Devops, Azure DevOps in Kukatpally, KPHB, Hyderabad.",
        keywords: 'Dot net training in KPHB Kukatpally, Best dot net training institute in KPHB Kukatpally, Best dot net training institute in JNTU Kukatpally, Best dot net training institute in JNTU Hyderabad, Dot net training with job in Hyderabad, Dot net training with project, Dot net training with placement in Hyderabad'
    },
    "/dotnet/join-now": {
        title: "Best DotNet Training Institute in Hyderabad - SSSIT Computer Education",
        description: "Training offered by Industry experts in Java, DotNet, Python, .Net core, Dotnet core, .Net, AWS & Devops, Azure DevOps in Kukatpally, KPHB, Hyderabad.",
        keywords: 'Dot net training in KPHB Kukatpally, Best dot net training institute in KPHB Kukatpally, Best dot net training institute in JNTU Kukatpally, Best dot net training institute in JNTU Hyderabad, Dot net training with job in Hyderabad, Dot net training with project, Dot net training with placement in Hyderabad'
    },
    "/dotnet/who-can-join": {
        title: "Best DotNet Training Institute in Hyderabad - SSSIT Computer Education",
        description: "Training offered by Industry experts in Java, DotNet, Python, .Net core, Dotnet core, .Net, AWS & Devops, Azure DevOps in Kukatpally, KPHB, Hyderabad.",
        keywords: 'Dot net training in KPHB Kukatpally, Best dot net training institute in KPHB Kukatpally, Best dot net training institute in JNTU Kukatpally, Best dot net training institute in JNTU Hyderabad, Dot net training with job in Hyderabad, Dot net training with project, Dot net training with placement in Hyderabad'
    },

    //Java Full Stack
    "/java-fullstack-training": {
        title: "Best Java Training Institute in Hyderabad - SSSIT Computer Education",
        description: "Training offered by Industry experts in Java, DotNet, Python, .Net core, Dotnet core, .Net, AWS & Devops, Azure DevOps in Kukatpally, KPHB, Hyderabad.",
        keywords: 'Best Java Training institute in hyderabad, best java training institute in kukatpally, best java training institute in KPHB, Java training in KPHB Kukatpally, Best Java training institute in KPHB Kukatpally, Best Java training institute in JNTU Kukatpally, Best Java training institute in JNTU Hyderabad, Java training with job in Hyderabad, Java training with project, Java training with placement in Hyderabad'
    },


    //Python Full Stack
    "/python": {
        title: "Best Python Training Institute in Hyderabad - SSSIT Computer Education",
        description: "Training offered by Industry experts in Java, DotNet, Python, .Net core, Dotnet core, .Net, AWS & Devops, Azure DevOps in Kukatpally, KPHB, Hyderabad.",
        keywords: 'Python training in KPHB Kukatpally, Best Python training institute in KPHB Kukatpally, Best Python training institute in JNTU Kukatpally, Best Python training institute in JNTU Hyderabad, Python training with job in Hyderabad, Python training with project, Python training with placement in Hyderabad'
    },
    "/python/content": {
        title: "Best Python Training Institute in Hyderabad - SSSIT Computer Education",
        description: "Training offered by Industry experts in Java, DotNet, Python, .Net core, Dotnet core, .Net, AWS & Devops, Azure DevOps in Kukatpally, KPHB, Hyderabad.",
        keywords: 'Python training in KPHB Kukatpally, Best Python training institute in KPHB Kukatpally, Best Python training institute in JNTU Kukatpally, Best Python training institute in JNTU Hyderabad, Python training with job in Hyderabad, Python training with project, Python training with placement in Hyderabad'
    },
    "/python/about-trainer": {
        title: "Best Python Training Institute in Hyderabad - SSSIT Computer Education",
        description: "Training offered by Industry experts in Java, DotNet, Python, .Net core, Dotnet core, .Net, AWS & Devops, Azure DevOps in Kukatpally, KPHB, Hyderabad.",
        keywords: 'Python training in KPHB Kukatpally, Best Python training institute in KPHB Kukatpally, Best Python training institute in JNTU Kukatpally, Best Python training institute in JNTU Hyderabad, Python training with job in Hyderabad, Python training with project, Python training with placement in Hyderabad'
    },
    "/python/our-success-stories": {
        title: "Best Python Training Institute in Hyderabad - SSSIT Computer Education",
        description: "Training offered by Industry experts in Java, DotNet, Python, .Net core, Dotnet core, .Net, AWS & Devops, Azure DevOps in Kukatpally, KPHB, Hyderabad.",
        keywords: 'Python training in KPHB Kukatpally, Best Python training institute in KPHB Kukatpally, Best Python training institute in JNTU Kukatpally, Best Python training institute in JNTU Hyderabad, Python training with job in Hyderabad, Python training with project, Python training with placement in Hyderabad'
    },
    "/python/our-training-methodology": {
        title: "Best Python Training Institute in Hyderabad - SSSIT Computer Education",
        description: "Training offered by Industry experts in Java, DotNet, Python, .Net core, Dotnet core, .Net, AWS & Devops, Azure DevOps in Kukatpally, KPHB, Hyderabad.",
        keywords: 'Python training in KPHB Kukatpally, Best Python training institute in KPHB Kukatpally, Best Python training institute in JNTU Kukatpally, Best Python training institute in JNTU Hyderabad, Python training with job in Hyderabad, Python training with project, Python training with placement in Hyderabad'
    },
    "/python/join-now": {
        title: "Best Python Training Institute in Hyderabad - SSSIT Computer Education",
        description: "Training offered by Industry experts in Java, DotNet, Python, .Net core, Dotnet core, .Net, AWS & Devops, Azure DevOps in Kukatpally, KPHB, Hyderabad.",
        keywords: 'Python training in KPHB Kukatpally, Best Python training institute in KPHB Kukatpally, Best Python training institute in JNTU Kukatpally, Best Python training institute in JNTU Hyderabad, Python training with job in Hyderabad, Python training with project, Python training with placement in Hyderabad'
    },
    "/python/who-can-join": {
        title: "Best Python Training Institute in Hyderabad - SSSIT Computer Education",
        description: "Training offered by Industry experts in Java, DotNet, Python, .Net core, Dotnet core, .Net, AWS & Devops, Azure DevOps in Kukatpally, KPHB, Hyderabad.",
        keywords: 'Python training in KPHB Kukatpally, Best Python training institute in KPHB Kukatpally, Best Python training institute in JNTU Kukatpally, Best Python training institute in JNTU Hyderabad, Python training with job in Hyderabad, Python training with project, Python training with placement in Hyderabad'
    },

    //Testing Tools
    "/testing": {
        title: "Best Testing Tools Training Institute in Hyderabad - SSSIT Computer Education",
        description: "Training offered by Industry experts in Java, DotNet, Python, .Net core, Dotnet core, .Net, AWS & Devops, Azure DevOps in Kukatpally, KPHB, Hyderabad.",
        keywords: 'Testing training in KPHB Kukatpally, Best Testing training institute in KPHB Kukatpally, Best Testing training institute in JNTU Kukatpally, Best Testing training institute in JNTU Hyderabad, Testing training with job in Hyderabad, Testing training with project, Testing training with placement in Hyderabad'
    },
    "/testing/content": {
        title: "Best Testing Tools Training Institute in Hyderabad - SSSIT Computer Education",
        description: "Training offered by Industry experts in Java, DotNet, Python, .Net core, Dotnet core, .Net, AWS & Devops, Azure DevOps in Kukatpally, KPHB, Hyderabad.",
        keywords: 'Testing training in KPHB Kukatpally, Best Testing training institute in KPHB Kukatpally, Best Testing training institute in JNTU Kukatpally, Best Testing training institute in JNTU Hyderabad, Testing training with job in Hyderabad, Testing training with project, Testing training with placement in Hyderabad'
    },
    "/testing/about-trainer": {
        title: "Best Testing Tools Training Institute in Hyderabad - SSSIT Computer Education",
        description: "Training offered by Industry experts in Java, DotNet, Python, .Net core, Dotnet core, .Net, AWS & Devops, Azure DevOps in Kukatpally, KPHB, Hyderabad.",
        keywords: 'Testing training in KPHB Kukatpally, Best Testing training institute in KPHB Kukatpally, Best Testing training institute in JNTU Kukatpally, Best Testing training institute in JNTU Hyderabad, Testing training with job in Hyderabad, Testing training with project, Testing training with placement in Hyderabad'
    },
    "/testing/our-success-stories": {
        title: "Best Testing Tools Training Institute in Hyderabad - SSSIT Computer Education",
        description: "Training offered by Industry experts in Java, DotNet, Python, .Net core, Dotnet core, .Net, AWS & Devops, Azure DevOps in Kukatpally, KPHB, Hyderabad.",
        keywords: 'Testing training in KPHB Kukatpally, Best Testing training institute in KPHB Kukatpally, Best Testing training institute in JNTU Kukatpally, Best Testing training institute in JNTU Hyderabad, Testing training with job in Hyderabad, Testing training with project, Testing training with placement in Hyderabad'
    },
    "/testing/our-training-methodology": {
        title: "Best Testing Tools Training Institute in Hyderabad - SSSIT Computer Education",
        description: "Training offered by Industry experts in Java, DotNet, Python, .Net core, Dotnet core, .Net, AWS & Devops, Azure DevOps in Kukatpally, KPHB, Hyderabad.",
        keywords: 'Testing training in KPHB Kukatpally, Best Testing training institute in KPHB Kukatpally, Best Testing training institute in JNTU Kukatpally, Best Testing training institute in JNTU Hyderabad, Testing training with job in Hyderabad, Testing training with project, Testing training with placement in Hyderabad'
    },
    "/testing/join-now": {
        title: "Best Testing Tools Training Institute in Hyderabad - SSSIT Computer Education",
        description: "Training offered by Industry experts in Java, DotNet, Python, .Net core, Dotnet core, .Net, AWS & Devops, Azure DevOps in Kukatpally, KPHB, Hyderabad.",
        keywords: 'Testing training in KPHB Kukatpally, Best Testing training institute in KPHB Kukatpally, Best Testing training institute in JNTU Kukatpally, Best Testing training institute in JNTU Hyderabad, Testing training with job in Hyderabad, Testing training with project, Testing training with placement in Hyderabad'
    },
    "/testing/who-can-join": {
        title: "Best Testing Tools Training Institute in Hyderabad - SSSIT Computer Education",
        description: "Training offered by Industry experts in Java, DotNet, Python, .Net core, Dotnet core, .Net, AWS & Devops, Azure DevOps in Kukatpally, KPHB, Hyderabad.",
        keywords: 'Testing training in KPHB Kukatpally, Best Testing training institute in KPHB Kukatpally, Best Testing training institute in JNTU Kukatpally, Best Testing training institute in JNTU Hyderabad, Testing training with job in Hyderabad, Testing training with project, Testing training with placement in Hyderabad'
    },

    //Digital Marketing
    "/digital-marketing": {
        title: "Best Digital Marketing Training Institute in Hyderabad - SSSIT Computer Education",
        description: "Training offered by Industry experts in Java, DotNet, Python, .Net core, Dotnet core, .Net, AWS & Devops, Azure DevOps in Kukatpally, KPHB, Hyderabad.",
        keywords: 'Dot net training in KPHB Kukatpally, Best dot net training institute in KPHB Kukatpally, Best dot net training institute in JNTU Kukatpally, Best dot net training institute in JNTU Hyderabad, Dot net training with job in Hyderabad, Dot net training with project, Dot net training with placement in Hyderabad'
    },
    "/digital-marketing/content": {
        title: "Best Digital Marketing Training Institute in Hyderabad - SSSIT Computer Education",
        description: "Training offered by Industry experts in Java, DotNet, Python, .Net core, Dotnet core, .Net, AWS & Devops, Azure DevOps in Kukatpally, KPHB, Hyderabad.",
        keywords: 'Dot net training in KPHB Kukatpally, Best dot net training institute in KPHB Kukatpally, Best dot net training institute in JNTU Kukatpally, Best dot net training institute in JNTU Hyderabad, Dot net training with job in Hyderabad, Dot net training with project, Dot net training with placement in Hyderabad'
    },
    "/digital-marketing/about-trainer": {
        title: "Best Digital Marketing Training Institute in Hyderabad - SSSIT Computer Education",
        description: "Training offered by Industry experts in Java, DotNet, Python, .Net core, Dotnet core, .Net, AWS & Devops, Azure DevOps in Kukatpally, KPHB, Hyderabad.",
        keywords: 'Dot net training in KPHB Kukatpally, Best dot net training institute in KPHB Kukatpally, Best dot net training institute in JNTU Kukatpally, Best dot net training institute in JNTU Hyderabad, Dot net training with job in Hyderabad, Dot net training with project, Dot net training with placement in Hyderabad'
    },
    "/digital-marketing/our-success-stories": {
        title: "Best Digital Marketing Training Institute in Hyderabad - SSSIT Computer Education",
        description: "Training offered by Industry experts in Java, DotNet, Python, .Net core, Dotnet core, .Net, AWS & Devops, Azure DevOps in Kukatpally, KPHB, Hyderabad.",
        keywords: 'Dot net training in KPHB Kukatpally, Best dot net training institute in KPHB Kukatpally, Best dot net training institute in JNTU Kukatpally, Best dot net training institute in JNTU Hyderabad, Dot net training with job in Hyderabad, Dot net training with project, Dot net training with placement in Hyderabad'
    },
    "/digital-marketing/our-training-methodology": {
        title: "Best Digital Marketing Training Institute in Hyderabad - SSSIT Computer Education",
        description: "Training offered by Industry experts in Java, DotNet, Python, .Net core, Dotnet core, .Net, AWS & Devops, Azure DevOps in Kukatpally, KPHB, Hyderabad.",
        keywords: 'Dot net training in KPHB Kukatpally, Best dot net training institute in KPHB Kukatpally, Best dot net training institute in JNTU Kukatpally, Best dot net training institute in JNTU Hyderabad, Dot net training with job in Hyderabad, Dot net training with project, Dot net training with placement in Hyderabad'
    },
    "/digital-marketing/join-now": {
        title: "Best Digital Marketing Training Institute in Hyderabad - SSSIT Computer Education",
        description: "Training offered by Industry experts in Java, DotNet, Python, .Net core, Dotnet core, .Net, AWS & Devops, Azure DevOps in Kukatpally, KPHB, Hyderabad.",
        keywords: 'Dot net training in KPHB Kukatpally, Best dot net training institute in KPHB Kukatpally, Best dot net training institute in JNTU Kukatpally, Best dot net training institute in JNTU Hyderabad, Dot net training with job in Hyderabad, Dot net training with project, Dot net training with placement in Hyderabad'
    },
    "/digital-marketing/who-can-join": {
        title: "Best Digital Marketing Training Institute in Hyderabad - SSSIT Computer Education",
        description: "Training offered by Industry experts in Java, DotNet, Python, .Net core, Dotnet core, .Net, AWS & Devops, Azure DevOps in Kukatpally, KPHB, Hyderabad.",
        keywords: 'Dot net training in KPHB Kukatpally, Best dot net training institute in KPHB Kukatpally, Best dot net training institute in JNTU Kukatpally, Best dot net training institute in JNTU Hyderabad, Dot net training with job in Hyderabad, Dot net training with project, Dot net training with placement in Hyderabad'
    },

    //Web Development Angular
    "/webdevelopment-angular": {
        title: "Best Front End Trainging Institute in Hyderabad - SSSIT Computer Education",
        description: "Training offered by Industry experts in Java, DotNet, Python, .Net core, Dotnet core, .Net, AWS & Devops, Azure DevOps in Kukatpally, KPHB, Hyderabad.",
        keywords: 'Dot net training in KPHB Kukatpally, Best dot net training institute in KPHB Kukatpally, Best dot net training institute in JNTU Kukatpally, Best dot net training institute in JNTU Hyderabad, Dot net training with job in Hyderabad, Dot net training with project, Dot net training with placement in Hyderabad'
    },
    "/webdevelopment-angular/content": {
        title: "Best Front End Trainging Institute in Hyderabad - SSSIT Computer Education",
        description: "Training offered by Industry experts in Java, DotNet, Python, .Net core, Dotnet core, .Net, AWS & Devops, Azure DevOps in Kukatpally, KPHB, Hyderabad.",
        keywords: 'Dot net training in KPHB Kukatpally, Best dot net training institute in KPHB Kukatpally, Best dot net training institute in JNTU Kukatpally, Best dot net training institute in JNTU Hyderabad, Dot net training with job in Hyderabad, Dot net training with project, Dot net training with placement in Hyderabad'
    },
    "/webdevelopment-angular/about-trainer": {
        title: "Best Front End Trainging Institute in Hyderabad - SSSIT Computer Education",
        description: "Training offered by Industry experts in Java, DotNet, Python, .Net core, Dotnet core, .Net, AWS & Devops, Azure DevOps in Kukatpally, KPHB, Hyderabad.",
        keywords: 'Dot net training in KPHB Kukatpally, Best dot net training institute in KPHB Kukatpally, Best dot net training institute in JNTU Kukatpally, Best dot net training institute in JNTU Hyderabad, Dot net training with job in Hyderabad, Dot net training with project, Dot net training with placement in Hyderabad'
    },
    "/webdevelopment-angular/our-success-stories": {
        title: "Best Front End Trainging Institute in Hyderabad - SSSIT Computer Education",
        description: "Training offered by Industry experts in Java, DotNet, Python, .Net core, Dotnet core, .Net, AWS & Devops, Azure DevOps in Kukatpally, KPHB, Hyderabad.",
        keywords: 'Dot net training in KPHB Kukatpally, Best dot net training institute in KPHB Kukatpally, Best dot net training institute in JNTU Kukatpally, Best dot net training institute in JNTU Hyderabad, Dot net training with job in Hyderabad, Dot net training with project, Dot net training with placement in Hyderabad'
    },
    "/webdevelopment-angular/our-training-methodology": {
        title: "Best Front End Trainging Institute in Hyderabad - SSSIT Computer Education",
        description: "Training offered by Industry experts in Java, DotNet, Python, .Net core, Dotnet core, .Net, AWS & Devops, Azure DevOps in Kukatpally, KPHB, Hyderabad.",
        keywords: 'Dot net training in KPHB Kukatpally, Best dot net training institute in KPHB Kukatpally, Best dot net training institute in JNTU Kukatpally, Best dot net training institute in JNTU Hyderabad, Dot net training with job in Hyderabad, Dot net training with project, Dot net training with placement in Hyderabad'
    },
    "/webdevelopment-angular/join-now": {
        title: "Best Front End Trainging Institute in Hyderabad - SSSIT Computer Education",
        description: "Training offered by Industry experts in Java, DotNet, Python, .Net core, Dotnet core, .Net, AWS & Devops, Azure DevOps in Kukatpally, KPHB, Hyderabad.",
        keywords: 'Dot net training in KPHB Kukatpally, Best dot net training institute in KPHB Kukatpally, Best dot net training institute in JNTU Kukatpally, Best dot net training institute in JNTU Hyderabad, Dot net training with job in Hyderabad, Dot net training with project, Dot net training with placement in Hyderabad'
    },
    "/webdevelopment-angular/who-can-join": {
        title: "Best Front End Trainging Institute in Hyderabad - SSSIT Computer Education",
        description: "Training offered by Industry experts in Java, DotNet, Python, .Net core, Dotnet core, .Net, AWS & Devops, Azure DevOps in Kukatpally, KPHB, Hyderabad.",
        keywords: 'Dot net training in KPHB Kukatpally, Best dot net training institute in KPHB Kukatpally, Best dot net training institute in JNTU Kukatpally, Best dot net training institute in JNTU Hyderabad, Dot net training with job in Hyderabad, Dot net training with project, Dot net training with placement in Hyderabad'
    },
};
